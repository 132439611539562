import React from 'react';
// components
import ProductSeo from '../../components/ProductSeo';
import Schedule from '../../sections/Product/components/common/Schedule';
import Identities from '../../sections/Product/IDV/Identities';
import Information from '../../sections/Product/IDV/Information';
import HeroSection from '../../sections/Product/components/common/HeroSection';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import { idProductPageFaq } from '../../helper/constant';
// assets
import IDV_demo from '../../images/product-assets/IDV-product-page-main-image.gif';
// layout
import RootLayout from '../../layouts/RootLayout';

export default function IdentityPage() {
    return (
        <>
            <ProductSeo
                title="Identity Verification Solutions & KYC Services | Streamline Customer Onboarding"
                description="Discover top-notch identity verification solutions and KYC services to enhance security and compliance. Learn how to streamline customer onboarding processes with cutting-edge technologies."
                ogImage="https://cdn.authenticating.com/public/website/product_pages/identity_verifcation_og_image.png"
            />
            <RootLayout>
                <HeroSection
                    tag="Products · Identity Verification"
                    heading="Identity Verification Made Simple"
                    subHeading="Instantly verify users and get real-time results. Use knowledge quizzes, bank account logins, or forensically analyze any government-issued photo ID for fraud, with optional facial recognition and liveness detection for enhanced security."
                    heroImage={IDV_demo}
                />
                <Identities />
                <Information />
                <ProductFaq faqData={idProductPageFaq} />
                <Schedule />
            </RootLayout>
        </>
    );
}
